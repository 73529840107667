import { getAddress, addAddress, deleteAddress } from '@/api/address'

const state = {
  addressList: [],
  selectedAddress: {},
  deliveryType: '1',  // 0 - 商家配送，1 - 门店自提
  pickupStoreId: undefined, // 自提门店id
  pickupDate: '',   // YYYY-MM-DD
  deliveryDate: '', // YYYY-MM-DD
  pickupTimespan: '',
  deliveryTimespan: ''
}

const mutations = {
  SET_ADDRESS_LIST: (state, list) => {
    state.addressList = list
  },
  SET_SELECTED_ADDRESS: (state, item) => {
    state.selectedAddress = item
  },
  SET_DELIVERY_TYPE: (state, type) => {
    state.deliveryType = type
  },
  SET_PICKUP_STORE_ID: (state, id) => {
    state.pickupStoreId = id
  },
  SET_DELIVERY_DATE: (state, date) => {
    state.deliveryDate = date
  },
  SET_PICKUP_DATE: (state, date) => {
    state.pickupDate = date
  },
  SET_PICKUP_TIMESPAN: (state, span) => {
    state.pickupTimespan = span
  },
  SET_DELIVERY_TIMESPAN: (state, span) => {
    state.deliveryTimespan = span
  }
}

const actions = {
  // 获取地址列表
  getList({ commit, state }) {
    return new Promise((resolve, reject) => {
      getAddress()
        .then(res => {
          const { data } = res
          commit('SET_ADDRESS_LIST', data)

          if (state.selectedAddress.id) {
            // 如果当前选中了地址，重复设置一遍，防止数据出现不对应的情况
            for (let a of state.addressList) {
              if (a.id === state.selectedAddress.id) {
                commit('SET_SELECTED_ADDRESS', a)
                break
              }
            }
          } else {
            // 如果当前没有选中的地址，则要把地址列表中ifDefault == true的那一项作为当前选中地址
            for (let a of state.addressList) {
              if (a.isDefault) {
                commit('SET_SELECTED_ADDRESS', a)
                break
              }
            }
          }
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 设置选中的地址
  setSelected({ commit, state }, index) {
    commit('SET_SELECTED_ADDRESS', state.addressList[index])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
