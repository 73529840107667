const getters = {
  // base info
  id: state => state.user.id,
  name: state => state.user.name,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,

  // address
  addressList: state => state.address.addressList,
  selectedAddress: state => state.address.selectedAddress,
  deliveryType: state => state.address.deliveryType,
  pickupStoreId: state => state.address.pickupStoreId,
  deliveryDate: state => state.address.deliveryDate,
  pickupDate: state => state.address.pickupDate,
  deliveryTimespan: state => state.address.deliveryTimespan,
  pickupTimespan: state => state.address.pickupTimespan,

  // search
  searchKey: state => {
    if (state.search.searchKey.length <= 0) {
      return JSON.parse(localStorage.getItem('searchKey')) || []
    } else {
      return state.search.searchKey
    }
  }
}

export default getters
